type SkineUrlOptions = {
  utmMedium?: string;
  utmCampaign?: string;
}

export const createSkineUrl = ( path?: string, options?: SkineUrlOptions ) => {
  const url = new URL( 'https://skine.com' );
  if ( path ) {
    url.pathname = path;
  }

  url.searchParams.append( 'utm_source', 'rewarble.com' );
  if ( options?.utmMedium ) {
    url.searchParams.append( 'utm_medium', options.utmMedium )
  }

  if ( options?.utmCampaign ) {
    url.searchParams.append( 'utm_campaign', options.utmCampaign );
  }

  return url.toString();
}
